<template>
  <div>
    <h3 style="text-align: center">《⽤户隐私政策》</h3>
    <h4>特别提醒</h4>
    <p>
      欢迎使用我们的网站/应用程序。<b
        >本隐私政策将说明我们如何收集、使用和保护您的个人信息。请仔细阅读本政策，以了解我们对您个人信息的处理方式。</b
      >
    </p>
    <h4>一、联系我们</h4>
    <p>
      为有效管理、保护您的个人信息，我们设立了专门的个人信息保护的负责人，如果您对本政策或个人信息保护有任何疑问、建议，可通过以下方式和我们进行联系，我们会在十五个工作日内受理并处理：
    </p>
    <ul>
      <li>地址：苏州钧信自动控制有限公司（收）</li>
      <li>邮编：215200</li>
      <li>邮箱：webmaster@servodynamics.com.cn</li>
      <li>电话：0512-82079388</li>
    </ul>
    <h4>二、我们处理个人信息的原则</h4>
    <p>
      我们采取一系列安全措施来保护您的个人信息的安全性。但请注意，互联网并非绝对安全的环境，因此我们无法保证您的个人信息的绝对安全,同时我们不会向第三方出售、交换或转让您的个人信息，除非事先获得您的明确同意。我们可能会与合作伙伴共享某些信息，以提供更好的服务和用户体验。
    </p>
    <h4>三、帮助您成为我们的用户及账户管理</h4>
    <p><strong>注册</strong></p>
    <p>
      为帮助您成为我们的用户，即完成微信公众号的授权绑定并使用账号登录、使用本服务，以便我们为您提供注册我们用户服务，我们为您提供了多种注册渠道供您自行选择：
    </p>
    <ul>
      <li>
        当您选择使用手机号码或电子邮箱注册时，您需要向我们提供您的<strong>手机号码</strong>，我们使用这类信息发送验证码信息以供您提交验证身份是否有效。
      </li>
      <li>
        基于我们与通信运营商的合作，经过您的同意，运营商会将您的<strong>手机号码</strong>发送给我们，便于我们为您提供快捷的登录服务。
      </li>
    </ul>
    <h4>四、我们如何使用Cookie和类似技术</h4>
    <p><b>4.1Cookie</b></p>
    <p>
      Cookie和类似技术：我们可能会使用Cookie和类似技术来提供个性化的用户体验、分析网站流量和优化广告。您可以根据自己的偏好进行浏览器设置，选择接受或拒绝Cookie。
    </p>
    <p><b>4.2请勿追踪</b></p>
    <p>
      您可根据自己的偏好管理或删除Cookie。您可以清除您的设备上保存的所有Cookie，很多网络浏览器均设有Do
      Not Track功能，该功能可向网站发布Do Not
      Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do
      Not Track，那么我们的所有网站都会尊重您的选择。
    </p>
    <h4>五、第三方链接</h4>
    <p>
      我们的网站可能包含第三方的链接，点击这些链接后您将离开我们的网站。我们无法控制这些第三方网站的隐私做法，因此建议您在离开我们的网站后查阅相应的隐私政策。
    </p>
    <h4>六、我们如何处理未成年⼈的信息</h4>
    <p>
      我们⾮常重视未成年⼈的个⼈信息保护，我们不接受任何未成年⼈注册成为我们的⽤户。
      如您的应⽤是针对未成年⼈⽽开发的，请您采取必要的措施确保您最终⽤户的注册与使⽤均已获得其监护⼈的同意，同时，您应在您的隐私权政策中履⾏相
      应的告知义务。提醒您注意的是，作为未成年⼈类的应⽤，您还需要遵守应⽤市场有关未成年⼈类应⽤的审核标准与指南，以免对您的应⽤上架或正常运营
      产⽣影响。
      受制于现有技术和业务模式，我们很难主动识别未成年⼈的信息，如果未成年⼈的监护⼈发现存在未经授权⽽收集未成年⼈个⼈信息，监护⼈可通知我们予
      以删除，如我们⾃⼰发现前述情形，我们也会主动予以删除。
    </p>
    <h4>七、您以及您最终⽤户的个⼈信息存储与全球范围转移</h4>
    <p>
        若您使⽤我们提供的海外版SDK服务，您⼗分清楚并理解，不同国家或地区对于数据及个⼈信息的收集、存储、使⽤、共享等各有其监管要求，您应主动遵
守海外国家或地区的法律法规和监管要求。您同意仅将我们提供的海外版SDK服务⽤于⼤陆地区以外的国家或地区，如您将我们提供的海外版SDK服务应⽤
于中华⼈民共和国⼤陆地区，您可能涉嫌违反《中华⼈民共和国⽹络安全法》的有关跨境传输的相关规定，由此引发的相应风险及后果均由您⾃⾏承担；如
本公司因此遭受任何形式的诉请、诉求、投诉、处罚等的，您将负责全⾯给予解决；如导致本公司发⽣任何形式的损失，您负责给予我们全额赔偿。同
时，本公司保留停⽌相关数据统计服务的权利。</p>
    <h4>八、隐私政策的更新</h4>
    <p>
      我们可能适时会对本隐私政策进⾏调整或变更，本隐私政策的任何更新将以标注更新时间的⽅式公布在微信公众号中，除法律法规或监管规定另有强制性
      规定外，经调整或变更的内容⼀经通知或公布后的7⽇后⽣效。未经您明确同意我们不会限制您按照本政策所应享有的权利。对于重⼤变更，我们还会提供更
      为显著的通知。 本政策所指的重⼤变更包括但不限于： </p>
      <p>1、我们的服务模式发⽣重⼤变化。如处理⽤户信息的⽬的、⽤户信息的使⽤⽅式等。</p>
      <p>2、我们在控制权、组织架构等⽅⾯发⽣重⼤变化。如业务调整、破产并购等引起的所有者变更等。</p>
      <p>3、个⼈信息共享、转让或公开披露的主要对象发⽣变化。</p> 
      <p>4、您参与个⼈信息处理⽅⾯的权利及其⾏使⽅式发⽣重⼤变化；</p>
      <p>5、我们负责处理⽤户信息安全的责任部门、联络⽅式及投诉渠道发⽣变化时。</p>
      <p>6、个⼈信息安全影响评估报告表明存在⾼风险时。</p>
   
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>