<template>
  <div class="form-container">
    <van-field
      v-model="date"
      label="日期"
      placeholder="请选择日期"
      type="date"
      class="form-item"
    />
    <van-field name="uploader" label="文件上传" class="form-item">
      <template #input>
        <van-uploader v-model="uploader" type="file" class="uploader-input" />
      </template>
    </van-field>
    <van-field
      v-model="message"
      rows="2"
      autosize
      label="留言"
      type="textarea"
      :maxlength="maxMessageLength"
      placeholder="请输入留言"
      show-word-limit
      @input="updateMaxMessageLength"
      class="form-item"
    />
    <div style="margin: 16px">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        @click="submitForm"
        class="submit-button"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      uploader: [],
      date: "",
      message: "",
      maxMessageLength: 1000, // 最大留言长度
    };
  },

  methods: {
    updateMaxMessageLength() {
      this.maxMessageLength = 1000 - this.message.length;
    },
    submitForm() {
      this.handleFileUpload();
    },
    handleFileUpload() {
      let registerId = localStorage.getItem("registerId");
      const formData = new FormData();
      formData.append("file", this.uploader[0].file);
      formData.append("date", this.date);
      formData.append("message", this.message);
      formData.append("registerId", registerId);
      axios
        .post("http://localhost:9527/api/uploadFile", formData)
        .then((res) => {
          this.$message.success("文件上传成功，等待管理员审核！");
          this.uploader = []; // 清空上传文件状态
          this.date = "";
          this.message = "";

          axios
            .post("http://localhost:9527/api/notifyAdmin", {
              registerId: registerId,
            })
            .then((res) => {
              console.log("res", res);
              this.$message.success("已通知管理员审核！");
            });
          //返回center页面
          this.$router.push({
            name: "center",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
/* 设置整个表单容器的样式 */
.form-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f5f5f5;
  height: 1000px;
  border-radius: 8px;
  padding-top: 50px;
}

/* 设置每个表单项的样式 */
.form-item {
  width: 100%;
  margin-bottom: 16px;
}

/* 设置日历输入框的样式 */
.calendar-input {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* 设置文件上传输入框的样式 */
.uploader-input {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
}

/* 设置留言文本框的样式 */
.message-textarea {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  resize: vertical; /* 允许垂直调整大小 */
}

/* 设置提交按钮的样式 */
.submit-button {
  width: 100%;
  margin-top: 16px;
}
</style>