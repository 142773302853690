<template>
  <div class="approval-container">
    <van-field v-model="position" label="姓名" placeholder="请输入姓名" />
    <van-field v-model="department" label="部门" placeholder="请输入部门" />
    <van-field
      v-model="date"
      label="日期"
      placeholder="请选择日期"
      type="date"
    />
    <van-field v-model="message" label="描述" placeholder="请输入描述" />
    <img
      v-if="filePath"
      :src="filePath"
      alt="上传的图片"
      class="uploaded-image"
    />

    <van-field v-model="status" label="审批状态" readonly />
    <van-button
      v-if="!isApproved"
      class="approval-button primary"
      @click="approve"
      >同意</van-button
    >
    <van-button v-if="!isApproved" class="approval-button default" @click="pass"
      >Pass</van-button
    >
    <van-button v-if="isApproved" class="approval-button primary" disabled
      >已审批</van-button
    >
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      position: "",
      department: "",
      status: "未审批",
      date: "",
      message: "",
      filePath: "",
      isApproved: false,
    };
  },
  mounted() {},
  created() {
    // this.approval();
  },
  methods: {
    // approval() {
    //   // 发送请求获取当前用户信息
    //   axios
    //     .post("http://localhost:9527/api/notifyAdmin")
    //     .then((res) => {
    //       const currentUser = res.data; // 获取数据库中当前用户信息
    //       console.log("currentUser", currentUser);
    //       if (currentUser.department === "null") {
    //         this.isManager = true;
    //       } else {
    //         this.isManager = false;
    //         this.$toast("您不是管理部门，无法审批");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("获取当前用户信息出错:", error);
    //       this.$toast("获取当前用户信息出错");
    //     });
    // },

    approve() {
      // 处理同意审批的逻辑
      this.$toast(`姓名: ${this.position}, 部门: ${this.department}，已同意审批`);
      this.status = "已审批";
      this.isApproved = true;
    },
    pass() {
      // 处理 Pass 审批的逻辑
      this.$toast(`姓名: ${this.position}, 部门: ${this.department}，已 Pass 审批`);
      this.status = "已审批";
      this.isApproved = true;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
  
<style lang="less" scoped>
.approval-container {
  padding: 20px;
}

.uploaded-image {
  max-width: 100%;
  margin-top: 10px;
}

.approval-container {
  padding: 20px;
}

.uploaded-image {
  max-width: 100%;
  margin-top: 10px;
}

.approval-button {
  margin-top: 20px;
  width: 120px;
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
}

.approval-button.primary {
  background-color: #1989fa;
  color: #fff;
}

.approval-button.default {
  background-color: #fff;
  color: #1989fa;
  border: 1px solid #1989fa;
}

.approval-button[disabled] {
  opacity: 0.5;
}
</style>