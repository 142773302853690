<template>
  <div class="motor-drive-container">
    <div class="image-container">
      <img
        :src="imageSrc"
        alt="电机驱动图片"
        class="responsive-image"
        ref="image"
      />
      <div class="overlay-container">
        <div
          v-for="(item, id) in filteredItems"
          :key="id"
          class="carousel-overlay"
          @click="playVideo(item.video_id)"
        >
          <img :src="item.files" class="motor-drive-pic" />
          <div class="date-price">
            <span>{{item.date}}</span>
            <span>{{item.area}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      main_id:'392001491096',
      imageSrc:
        "http://wx.servodynamics.com.cn/source/static/wisdom_camp_image/feedback_measurement.jpg",
      items: [], // 增加了更多的项目
      filteredItems: [] //过滤后的数据
    };
  },
  created() {
    this.cover_title();
    this.playVideo();
  },
  methods: {
    async cover_title() {
      try {
        const res = await axios.get('http://wx.servodynamics.com.cn:9527/api/cover_title_do',{
          params:{
            main_id: this.main_id
          }
        });
        this.items = res.data.data.map((item)=>({
          date: item.date,
          area: item.area,
          files:item.files,
          main_id: item.main_id,
          video_id: item.video_id,
        }))
        this.filteredItems = this.items.filter(item => item.main_id === this.main_id)
      } catch(error) {
        console.error('error fetching data:',error)
      }
    },
    async playVideo(video_id) {
      try {
        const res = await axios.get("http://wx.servodynamics.com.cn:9527/api/video_list_do", {
          params: {
            main_id: this.main_id,
            video_id: video_id,
          },
        });

        if (res.data.code === "ok" && res.data.data.length > 0) {
          const videoData = res.data.data[0];

          // 获取 registerId 从localstorage
          const registerId = localStorage.getItem("registerId");

          // 获取预览token
          const tokenRes = await axios.post(
            "http://wx.servodynamics.com.cn:9527/api/preview/token",
            {
              file_id: videoData.video_id,
              period: 7200,
              registerId: registerId,
            }
          );
          if (tokenRes.data.code === "success") {
            const previewUrl = tokenRes.data.preview_url;
            window.location.href = previewUrl;
          } else {
            console.error('Failed to get preview URL:', previewUrlRes.data.message);
          }
        }
      } catch (error) {
        console.error("Error fetching video list:", error);
        console.log("加载视频列表时出错");
      }
    }
  },
};
</script>
<style lang="less" scoped>
.motor-drive-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.responsive-image {
  max-width: 100%;
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.overlay-container {
  position: absolute;
  top: 210px;
  left: 0;
  width: 100%;
  height: calc(85% - 130px);
  overflow-y: auto; // 允许垂直滚动
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 每行两个元素
  grid-gap: 15px;
  padding: 10px;
  box-sizing: border-box; // 确保 padding 不影响高度
}

.date-price {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  background-color: rgba(64, 73, 90, 0.8);
  color: #fff;
  width: 100%; // 确保与父元素宽度相同
}

.date-price span:first-child,
.date-price span:nth-child(1) {
  display: block;
}

.motor-drive-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 4px;
  width: 100%; // 占据整个容器宽度
  height: 130px;
  margin-bottom: 26px; // 底部间距
}
</style>