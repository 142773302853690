<template>
  <van-action-sheet v-model="showPopup" position="bottom" style="height: 45%">
    <template #default>
      <div class="page">
        <div class="weui-form__bd">
          <div class="weui-cells__group weui-cells__group_form">
            <label for="js_input0" class="weui-cell weui-cell_active">
              <img
                src="http://wx.servodynamics.com.cn/source/static/images/Servo.png"
                alt=""
              />
              <p style="font-weight: bold; font-size: 14px">
                &nbsp;&nbsp;苏州钧信
              </p>
              <p style="font-weight: bold; font-size: 14px">&nbsp;&nbsp;申请</p>
            </label>
            <label for="js_input1" class="weui-cell weui-cell_active">
              <p style="font-weight: bold">获取你的昵称、头像、手机号</p>
            </label>
            <label for="js_input2" class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <img class="avatar" :src="userData.avatarUrl" />
              </div>
              <div
                class="weui-cell__bd"
                style="display: flex; flex-direction: column"
              >
                <span class="nickname">{{ userData.nickname }}</span>
                <span class="nickname" style="color: #999">微信昵称头像</span>
              </div>
            </label>
            <label for="js_input3" class="weui-cell weui-cell_active">
              <div class="weui-cell__hd">
                <span class="nickname">绑定手机号</span>
              </div>
              <div class="weui-cell__bd">
                <input
                  id="js_input3"
                  class="weui-input"
                  type="number"
                  pattern="[0-9]*"
                />
              </div>
            </label>
          </div>
          <div
            style="
              margin-top: 10px;
              display: flex;
              justify-content: center;
              padding-bottom: env(safe-area-inset-bottom);
            "
          >
            <van-button color="#05C15F" style="width: 40%" @click="handleCancel"
              ><span style="color: #fff; font-weight: bold"
                >取消</span
              ></van-button
            >
            <div style="width: 20px"></div>
            <van-button style="width: 40%" color="#F2F2F2" @click="handleAgree"
              ><span style="color: #05c15f; font-weight: bold"
                >同意</span
              ></van-button
            >
          </div>
        </div>
        <!-- </div> -->
      </div>
    </template>
  </van-action-sheet>
</template>
<script>
import axios from "axios";
import { ActionSheet } from "vant";
import { Button } from "vant";
export default {
  name: "center",
  components: {
    ActionSheet,
    Button,
  },
  data() {
    return {
      money: 0,
      isAuthorized: false,
      avatarUrl: "",
      isRegistered: false,
      user: {},
      userData: {},
      showPopup: false, // 控制弹窗显示与隐藏
    };
  },

  methods: {
    handleCancel() {
      console.log("用户取消");
    },
    handleAgree() {
      console.log("用户同意");
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.weui-cells__group_form {
  display: flex;
  flex-direction: column; /* 在小屏幕上垂直排列 */

  @media only screen and (min-width: 768px) {
    flex-direction: row; /* 在大屏幕上水平排列 */
  }
}

.weui-cell {
  width: 100%; /* 设置宽度为父容器的100% */

  @media only screen and (min-width: 768px) {
    width: 50%; /* 在大屏幕上设置宽度为父容器的50% */
  }
}
</style>