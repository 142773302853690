<template>
  <div class="main">
    <div class="all-container">
      <div class="header-container">
        <img
          v-if="!isAuthorized"
          src="@/assets/centerUser.png"
          alt=""
          @click="toLogin"
        />
        <img v-else :src="avatarUrl" alt="" />
        <span style="color: #fff" @click="toLogin" v-if="!isRegistered"
          >立即注册</span
        >
        <span v-else
          >{{ user.name }}<br />
          {{ user.phone }}</span
        >
      </div>
      <div class="order">
        <div class="tips" @click="toLogin">
          <div>0张</div>
          <div>优惠券</div>
        </div>
        <div class="money">
          <div>{{ points }}分</div>
          <div>我的积分</div>
        </div>
      </div>
      <div class="my-card" @click="toInformation">
        <img src="@/assets/tips.png" alt="" />
        <span class="label">信息变更</span>
        <img class="arrow" src="@/assets/right.png" alt="" />
      </div>
      <div class="my-card">
        <img src="@/assets/积分兑换.png" alt="" />
        <span class="label">积分兑换</span>
        <img class="arrow" src="@/assets/right.png" alt="" />
      </div>
      <div class="system-set" @click="gobackLogin">
        <img src="@/assets/set.png" alt="" />
        <span class="label">退出登录</span>
        <img class="arrow" src="@/assets/right.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "center",
  data() {
    return {
      points: 0,
      isAuthorized: false,
      avatarUrl: "",
      isRegistered: false,
      user: {},
      userData: {},
    };
  },
  // mounted(){
  //   this.initWechatJS_SDK();
  // },
  created() {
    this.fetchUserInfo();
    this.Init();
    this.getUserInfo();
    this.Authorization();
    this.fetchUserPoints();
  },
  methods: {
    Init() {
      let str = localStorage.getItem("id");
    },
    fetchUserPoints() {
      let registerId = localStorage.getItem("registerId");
      axios
        .post("http://wx.servodynamics.com.cn:9527/api/getUserPoints", {
          userId: registerId,
        })
        .then((pointsRes) => {
          if (pointsRes.data.code === "ok") {
            let points = pointsRes.data.points;
            this.points = points;
            // } else {
            //   this.$message.error("获取积分失败");
          }
        });
    },
    Authorization() {
      const openId = localStorage.getItem("openId");
      // 从localStorage中获取头像地址
      const avatarUrl = localStorage.getItem("avatarUrl");
      if (avatarUrl) {
        this.avatarUrl = decodeURIComponent(avatarUrl);
        this.isAuthorized = true;
      }
    },
    toLogin() {
      window.location.href = `http://wx.servodynamics.com.cn/api/wechat`;
    },
    gobackLogin() {
      this.$router.push("/register");
    },
    toInformation() {
      this.$router.push("/information");
    },
    async getUserInfo() {
      const urlParams = new URLSearchParams(
        new URL(window.location.href).hash.split("?")[1]
      );
      const avatarUrl = decodeURIComponent(urlParams.get("avatarUrl"));
      const code = urlParams.get("code");
      const openId = decodeURIComponent(urlParams.get("openId"));
      const registerId = urlParams.get("registerId");
      const nickname = decodeURIComponent(urlParams.get("nickname"));
      if (avatarUrl && code) {
        this.avatarUrl = decodeURIComponent(avatarUrl);
        const accessToken = urlParams.get("accessToken");

        this.isAuthorized = true;
        localStorage.setItem("hasAuthorized", "true"); // 已添加的 hasAuthorized
        localStorage.setItem("registerId", registerId);
        localStorage.setItem("nickname", nickname);
        localStorage.setItem("openId", openId);
        localStorage.setItem("avatarUrl", avatarUrl);
        localStorage.setItem("accessToken", accessToken);
        this.userData = {
          avatarUrl,
          nickname,
        };
      }
    },
    async fetchUserInfo() {
      try {
        const userId = localStorage.getItem("id");
        if (userId != undefined) {
          const response = await axios.get(
            `http://wx.servodynamics.com.cn:9527/api/user/${userId}`
          );
          this.user = response.data.data[0];
          this.isRegistered = true;
        }
      } catch (error) {
        console.error("Error during fetchUserInfo: ", error);
      }
    },

    // initWechatJS_SDK(){
    //   this.$wx.config({
    //     debug: false,
    //     appId: 'wxbd7fe9c214f981f4',
    //     timestamp: '1716368653',
    //     nonceStr: '1704213561',
    //     signature: '76f5a80d6768013ac59818fe151479dd42ba0b35',
    //     jsApiList: ['miniProgram.navigateTo']
    //   })
    // },
    // async ToMiniProgram() {
    //   let userId = localStorage.getItem("registerId");
    //   if (typeof wx === "undefined") {
    //     console.error("微信JS-SDK未加载,请检查配置");
    //     return;
    //   }
    //   try {
    //     await this.ensureWxReady(); // 确保微信JS-SDK准备就绪
    //     // 使用redirectToMiniProgram进行跳转，注意检查API的正确使用及参数
    //     const miniProgramOption = {
    //       appId: "wx2fc8ce9bdaaf95c5",
    //       path: "pages/index/index",
    //       envVersion: "release",
    //       extraData: { userId: userId },
    //     };
    //     this.$wx.miniProgram
    //       .navigateToMiniProgram(miniProgramOption)
    //       .then(() => {
    //         console.log("跳转成功");
    //       })
    //       .catch((err) => {
    //         console.error("跳转小程序失败", err);
    //       });
    //   } catch (error) {
    //     console.error("等待wx.ready时出错", error);
    //   }
    // },

    // 确保微信JS-SDK已经初始化完成
    // async ensureWxReady() {
    //   return new Promise((resolve, reject) => {
    //     if (typeof wx === "undefined" || !wx.ready) {
    //       document.addEventListener("WeixinJSBridgeReady", resolve, false); // 监听微信JSBridgeReady事件
    //     } else {
    //       resolve(); // 如果已经ready，则直接resolve
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.weui-cells__group_form {
  display: flex;
  flex-direction: column; /* 在小屏幕上垂直排列 */

  @media only screen and (min-width: 768px) {
    flex-direction: row; /* 在大屏幕上水平排列 */
  }
}

.weui-cell {
  width: 100%; /* 设置宽度为父容器的100% */

  @media only screen and (min-width: 768px) {
    width: 50%; /* 在大屏幕上设置宽度为父容器的50% */
  }
}

.header {
  height: 44px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
// 全页背景色
.main {
  height: 100vh;
  background-color: #f5f5f5;

  .all-container {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    font-size: 15px;
    background: #f4f4f4;
    margin-bottom: 59px;
    .header-container {
      margin-top: -44px;
      height: 200px;
      padding-left: 22px;
      padding-top: 64px;
      background-color: #2c3e50;
      background-size: cover;
      display: flex;
      align-items: center;
      img {
        width: 63px;
        height: 63px;
        border-radius: 35px;
        margin-right: 20px;
        border: 2px solid #fff;
      }
      span {
        font-size: 16px;
        color: #fff;
      }
    }
  }
  .order {
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    padding: 0;
    height: 79px;
    margin-bottom: 10px;
    background: #fff;
    align-items: center;
    .tips {
      flex: 1;
      color: #191a1b;
      font-size: 17px;
      div:nth-child(2) {
        font-size: 13px;
        color: #797d82;
      }
    }
    .money {
      flex: 1;
      color: #191a1b;
      font-size: 17px;
      div:nth-child(2) {
        font-size: 13px;
        color: #797d82;
      }
    }
  }
  .my-card,
  .system-set {
    width: 100%;
    height: 49px;
    padding: 0 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 20px;
      height: 20px;
    }
    .label {
      margin-left: 15px;
      color: #191a1b;
      flex: 1;
    }
    .arrow {
      text-align: right;
      width: 6px;
      height: 10px;
    }
  }
  .my-card:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #ededed;
    color: #ededed;
    left: 13px;
  }
}
</style>
