<template>
  <div>
    <div class="login">
      <img src="@/assets/loginserve.png" alt="" />
    </div>
    <div class="login-info">
      <div>
        <van-form validate-first ref="phoneFrom">
          <div>
            <van-field
              v-model.trim="name"
              label="公司名称"
              required
              type="name"
              placeholder=""
              :rules="[
                {
                  required: true,
                  message: '请输入正确的公司名称',
                  trigger: 'blur',
                },
                {
                  min: 4,
                  message: '公司名称最少不低于4个字符',
                  trigger: 'blur',
                },
                {
                  max: 25,
                  message: '公司名称不超过20个字符',
                  trigger: 'blur',
                },
              ]"
              @input="handleSearch"
            />
            <el-dropdown v-if="showDropdown" @command="handleSelect">
              <el-dropdown-item
                v-for="option in filteredCompanyOptions"
                :key="option"
                :command="option"
              >
                {{ option }}
              </el-dropdown-item>
              <el-dropdown-menu> </el-dropdown-menu>
            </el-dropdown>
            <van-field
              v-model.trim="email"
              label="邮箱"
              type="email"
              placeholder=""
            />
            <van-field
              v-model.trim="phone"
              label="手机号"
              required
              type="phone"
              placeholder=""
              :rules="[
                {
                  pattern:
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                  message: '请输入正确的手机号',
                },
              ]"
            />
            <van-field
              v-model.trim="code"
              center
              clearable
              label="验证码"
              placeholder=""
              :rules="[{ required: true, message: '请输入短信验证码' }]"
              :readonly="!isAuthorized"
            >
              <template #button>
                <van-button
                  size="small"
                  type="info"
                  @click="sendCode()"
                  v-if="!sendCodeVisible"
                >
                  发送验证码
                </van-button>
                <van-button size="small" type="info" v-else>
                  {{ !sendCodeVisible ? "发送验证码" : `${count}s` }}
                </van-button>
              </template>
            </van-field>
            <van-field
              v-model="position"
              label="姓名"
              :rules="formRules.position"
              required
            />
            <van-field
              v-model="department"
              label="部门"
              :rules="formRules.department"
              required
            />
            <van-dialog
              style="width: 300px; height: 240px"
              v-model="showCaptchaDialog"
              :show-confirm-button="false"
              :show-cancel-button="false"
            >
              <captcha
                ref="captchaComponent"
                @success="sendCodeAfterCaptcha"
              ></captcha>
            </van-dialog>
          </div>

          <div style="margin: 6px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="enter"
              >注册</van-button
            >
          </div>
          <van-checkbox
            v-model="checked"
            shape="square"
            checked-color="#1989fa"
            class="small-checkbox"
          >
            <span style="font-size: 13px">我已阅读并同意相关条款 </span>
            <span class="agreement-link" style="font-size: 13px" @click="rmd"
              >《用户协议》</span
            >
            和
            <span class="agreement-link" style="font-size: 13px" @click="cmd"
              >《隐私政策》</span
            >
          </van-checkbox>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import captcha from "@/views/captcha/captcha.vue";
// 定义一个过滤函数，去除非法字符或特殊符号
function filterSpecialCharacters(str) {
  // 这里可以根据实际需求编写过滤规则
  return str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "");
}
export default {
  components: {
    captcha, // 注册 captcha.vue 组件
  },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      companyOptions: [], // 公司全称列表
      selectedCompany: "", // 用户选择的公司全称
      showDropdown: false, // 是否显示下拉框
      code: "",
      count: 60,
      position: "",
      department: "",
      sendCodeVisible: false,
      isAuthorized: false,
      timer: null,
      $v: null, // 添加 $v 属性并初始化为 null
      checked: false, // 添加 checked 属性并初始化为 false
      isRegistered: false,
      showCaptchaDialog: false,
      requirePositionAndDepartment: false,
      formRules: {
        department: [{ required: false, message: "内部员工部门是必填项" }],
        position: [{ required: false, message: "内部员工姓名是必填项" }],
      },
    };
  },
  computed: {
    ...mapState(["info"]), // 使用mapState将store中的info映射到组件的computed属性中
    filteredCompanyOptions() {
      // 根据用户输入的公司名称过滤选项
      return this.companyOptions.filter((option) =>
        option.includes(filterSpecialCharacters(this.name))
      );
    },
  },
  watch: {
    name(newValue) {
      this.requirePositionAndDepartment =
        newValue === "苏州钧信自动控制有限公司";
      this.formRules.department[0].required = this.requirePositionAndDepartment;
      this.formRules.position[0].required = this.requirePositionAndDepartment;
    },
  },
  mounted() {
    this.getUserInfo();
  },
  created() {
    this.name = this.$store.state.info.name;
    this.phone = this.$store.state.info.phone;
    this.email = this.$store.state.info.email;
    this.position = this.$store.state.info.position;
    this.department = this.$store.state.info.department;
  },
  
  methods: {
    // 验证手机号格式
    isValidPhone(phone) {
      const regex =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return regex.test(phone);
    },
    // 验证公司名称的函数
    validateName(rule, value, callback) {
      const filteredValue = filterSpecialCharacters(value);
      if (filteredValue !== value) {
        callback(new Error("公司名称只能包含中文、英文字母和数字"));
      } else {
        callback(); // 验证通过
      }
    },
    sendCode() {
      if (!this.isValidPhone(this.phone)) {
        Dialog.alert({
          message: "请先输入手机号",
          theme: "",
        });
        return;
      }
      this.showCaptchaDialog = true;
    },
    // 获取验证码
    sendCodeAfterCaptcha() {
      if (!this.isValidPhone(this.phone)) {
        Dialog.alert({
          message: "请先输入手机号",
          theme: "",
        });
        return;
      }
      // 滑块验证成功后隐藏滑块
      this.showCaptchaDialog = false;
      axios
        .post("http://wx.servodynamics.com.cn:9527/api/sendCode", {
          phone: this.phone,
        })
        .then((res) => {
          if (res.data.code === "200") {
            this.sendCodeVisible = true;
            this.isAuthorized = true;
            this.code = "";
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              this.count--;
              if (this.count < 0) {
                clearInterval(this.timer);
                this.sendCodeVisible = false;
                this.count = 60;
                // 重置验证码对话框的状态
                this.resetCaptchaDialogState();
              }
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("发送验证码失败", error);
        });
    },
    // 重置验证码对话框的状态
    resetCaptchaDialogState() {
      this.$refs.captchaComponent.reset();
    },
    async handleSearch() {
      try {
        const response = await axios.get(
          "http://wx.servodynamics.com.cn:9527/api/search",
          {
            params: {
              keyword: filterSpecialCharacters(this.name),
            },
          }
        );
        this.companyOptions = response.data.result.items.map(
          (item) => item.name
        );
        this.showDropdown = true; // 显示下拉框

        // 处理返回的搜索结果
      } catch (error) {}
    },
    handleSelect(command) {
      this.name = command;
      this.selectedCompany = command;
      this.showDropdown = false;
    },
    async getUserInfo() {
      const urlParams = new URLSearchParams(
        new URL(window.location.href).hash.split("?")[1]
      );
      const avatarUrl = decodeURIComponent(urlParams.get("avatarUrl"));
      const code = urlParams.get("code");
      const openId = decodeURIComponent(urlParams.get("openId"));
      const registerId = urlParams.get("registerId");
      const nickname = decodeURIComponent(urlParams.get("nickname"));
      if (avatarUrl && code) {
        this.avatarUrl = decodeURIComponent(avatarUrl);
        const accessToken = urlParams.get("accessToken");
        this.isAuthorized = true;
        localStorage.setItem("hasAuthorized", "true"); // 已添加的 hasAuthorized
        localStorage.setItem("registerId", registerId);
        localStorage.setItem("openId", openId);
        localStorage.setItem("nickname", nickname);
        localStorage.setItem("avatarUrl", avatarUrl);
        localStorage.setItem("accessToken", accessToken);
      }
    },
    // 注册
    async enter() {
      try {
        await this.$refs.phoneFrom.validate();
        if (!this.checked) {
          Dialog.alert({
            message: "请同意用户协议和隐私政策",
            theme: "",
          });
          return;
        }
        let res = await axios.post(
          "http://wx.servodynamics.com.cn:9527/api/verifyCode",
          {
            phone: this.phone,
            code: this.code,
          }
        );

        if (res.data.code === "200") {
          const registerId = localStorage.getItem("registerId");

          // const menuTypes = ["调查抽奖", "账户信息", "客户投诉"]; // 定义菜单项的标识值数组
          // const customHeaderValue = menuTypes.join(","); // 将数组转换为以逗号分隔的字符串
          //   console.log('customHeaderValue',customHeaderValue);
          let resgisterData = {
            name: this.name,
            phone: this.phone,
            registerId: registerId,
            department: this.department,
            position: this.position,
          };
          if (
            (this.email !== "", this.department !== "", this.position !== "")
          ) {
            resgisterData.email = this.email;
            resgisterData.department = this.department;
            resgisterData.position = this.position;
          }

          let registerRes = await axios.post(
            "http://wx.servodynamics.com.cn:9527/api/register",
            resgisterData
            // {
            //   headers: {
            //     "Content-Type": "application/json",
            //     "Custom-Header": encodeURIComponent(customHeaderValue), // 添加自定义标识字段
            //   },
            // }
          );
          if (registerRes.data.status === 200) {
            Toast.success("注册成功");
            // 注册成功，则登录
            localStorage.setItem("isRegistered", true);

            this.isRegistered = true;
            const Id = registerRes.data.id;
            localStorage.setItem("id", Id);
            // this.$router.push("/center");
            this.$router.push({ name: "center", params: { id: Id } });
          }
        } else {
          Dialog.alert({
            message: "验证码错误",
            theme: "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    rmd() {
      this.$store.commit("saveInfo", {
        name: this.name,
        phone: this.phone,
        email: this.email,
        department: this.department,
        position: this.position,
      });
      this.$router.push("/rmd");
    },
    cmd() {
      this.$store.commit("saveInfo", {
        name: this.name,
        phone: this.phone,
        email: this.email,
        department: this.department,
        position: this.position,
      });
      this.$router.push("/cmd");
    },
    // 验证手机号
    validator() {
      if (this.$v && !this.$v.phone.$invalid) {
        // 检查 $v 是否存在
        return this.isValidPhone(this.phone);
      } else {
        return false;
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.login {
  margin: 79px auto 40px;
  text-align: center;
  height: 100px;
  line-height: 60px;
  img {
    height: 100px;
  }
}
.login-info {
  .from-group {
    line-height: 55px;
    margin: 0 25px;
    position: relative;
    input {
      height: 15px;
      line-height: 15px;
      padding: 20px 0;
      width: 100%;
      font-size: 15px;
      color: #191a1b;
      border: 0;
      outline-width: 0;
    }
    .disable {
      position: absolute;
      right: 0px;
      top: 13px;
      color: #bdc0c5 !important;
      line-height: 30px;
      width: 90px;
      text-align: right;
      float: right;
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
    }
    .clear-btn {
      position: absolute;
      top: 20px;
      right: 90px;
      width: 18px;
      height: 18px;
    }
  }
  .from-group:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ededed;
    transform-origin: 0 0;
    border-bottom: 1px solid #ededed;
    transform: scaleY(0.5);
  }
  .submit {
    margin-top: 70px;
    line-height: 45px;
    font-size: 16px;
    margin: 70px 25px 0;
    border-radius: 3px;
    text-align: center;
    background-color: #ff5f16;
    height: 44px;
    color: #fff;
    border: none;
    span {
      line-height: 45px;
      font-size: 17px;
      margin: 70px 25px 0;
      border-radius: 3px;
      text-align: center;
      height: 44px;
      color: #ff8c58;
      border: none;
    }
  }
  .can-not-get {
    text-align: right;
    margin: 15px 25px 0;
    color: #797d82;
    font-size: 13px;
    span {
      text-align: right;
      color: #797d82;
      font-size: 13px;
    }
  }
  .small-checkbox {
    transform: scale(0.9);
  }
}
</style>
  