var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('van-form',{ref:"modifyForm",attrs:{"validate-first":""}},[_c('van-field',{attrs:{"label":"公司名称","required":"","type":"name","placeholder":"","rules":[
        {
          required: true,
          message: '请输入正确的公司名称',
          trigger: 'blur',
        },
        {
          min: 4,
          message: '公司名称最少不低于4个字符',
          trigger: 'blur',
        },
        {
          max: 25,
          message: '公司名称不超过20个字符',
          trigger: 'blur',
        },
      ]},on:{"input":_vm.handleSearch},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}}),(_vm.showDropdown)?_c('el-dropdown',{on:{"command":_vm.handleSelect}},[_vm._l((_vm.filteredCompanyOptions),function(option){return _c('el-dropdown-item',{key:option,attrs:{"command":option}},[_vm._v(" "+_vm._s(option)+" ")])}),_c('el-dropdown-menu')],2):_vm._e(),_c('van-field',{attrs:{"type":"phone","label":"手机号","rules":[
        {
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
        },
      ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{attrs:{"type":"email","label":"邮箱","rules":[
        {
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          message: '请输入正确的邮箱',
        },
      ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('van-field',{attrs:{"label":"部门"},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('van-field',{attrs:{"label":"姓名"},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}}),_c('div',{staticStyle:{"margin":"6px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"modify"},on:{"click":_vm.updateUserInfo}},[_vm._v("完善信息")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('img',{attrs:{"src":require("@/assets/loginserve.png"),"alt":""}})])
}]

export { render, staticRenderFns }