<template>
  <div>
    <form class="formMessage">
      <div style="margin: 8px">
        <h2>客户投诉单</h2>
        <p>
          亲爱的用户：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;如果您对我们为您提供的服务有何意见和建议，或者您想咨询什么，您都可以<br />在这里留下您的想法，
          我们将尽快向您提供解答，谢谢！
        </p>
        <button type="button" @click="submitForm">提交</button>
        <table
          border="1"
          height="600px"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          style="text-align: center"
        >
          <tr>
            <th colspan="5">客户投诉单</th>
          </tr>
          <tr>
            <td>订单号</td>
            <td colspan="4">
              <input
                type="text"
                v-model="orderNumber"
                class="input-full-width"
              />
            </td>
          </tr>
          <tr>
            <td><b>投诉内容</b></td>
            <td colspan="4">
              <input type="text" v-model="tcontent" class="input-full-width" />
            </td>
          </tr>
          <tr>
            <td><b>拍照上传</b></td>
            <td colspan="4">
              <div>
                <input
                  type="file"
                  id="fileExport"
                  @change="handleFileChange"
                  ref="inputer"
                  multiple
                />
              </div>
            </td>
          </tr>
          <tr>
            <td><b>需解决问题或改进方向</b></td>
            <td colspan="4">
              <input type="text" v-model="solve" class="input-full-width" />
            </td>
          </tr>
          <tr style="font-size: #2c3e50">
            <td><b>产品名称</b></td>
            <td><b>规格</b></td>
            <td><b>单位</b></td>
            <td><b>数量</b></td>
            <td><b>序列号</b></td>
          </tr>
          <tr style="height: 35px">
            <td>
              <input type="text" v-model="name" class="input-full-width" />
            </td>
            <td>
              <input
                type="text"
                v-model="specification"
                class="input-full-width"
              />
            </td>
            <td>
              <input type="text" v-model="unit" class="input-full-width" />
            </td>
            <td>
              <input type="text" v-model="quantity" class="input-full-width" />
            </td>
            <td>
              <input
                type="text"
                v-model="serialNumber"
                class="input-full-width"
              />
            </td>
          </tr>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'msg',
  data() {
    return {
      orderNumber: "",
      tcontent: "",
      solve: "",
      name: "",
      specification: "",
      unit: "",
      quantity: "",
      serialNumber: "",
    };
  },
  methods: {
    handleFileChange() {
      let inputDOM = this.$refs.inputer;
      this.file = inputDOM.files[0]; // 通过DOM取文件数据
      this.formData = new FormData(); //new一个formData事件
      this.formData.append("file", this.file); //将file属性添加到formData里 //此时formData就是我们要向后台传的参数了
      this.formData.append("orderNumber", this.orderNumber);
    },
    submitForm() {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          "http://wx.servodynamics.com.cn:9527/api/upload",
          this.formData,
          config
        )
        .then((response) => {
          console.log(response);
        });
      // 检查表单数据是否为空
      if (
        !this.orderNumber ||
        !this.tcontent ||
        !this.solve ||
        !this.name ||
        !this.specification ||
        !this.unit ||
        !this.quantity ||
        !this.serialNumber ||
        !this.file
      ) {
        this.$message.error("请填写完整的表单内容");
        return; // 阻止表单提交
      }

      const formData = new FormData();
      formData.append("orderNumber", this.orderNumber);
      formData.append("tcontent", this.tcontent);
      formData.append("solve", this.solve);
      formData.append("name", this.name);
      formData.append("specification", this.specification);
      formData.append("unit", this.unit);
      formData.append("quantity", this.quantity);
      formData.append("serialNumber", this.serialNumber);
      formData.append("registerId", localStorage.getItem('registerId')); // 从localStorage中获取registerId
      axios
        .post("http://wx.servodynamics.com.cn:9527/api/submit", formData)
        .then((res) => {
          this.$message.success("提交成功");
          // 清空表单数据
          this.orderNumber = "";
          this.tcontent = "";
          this.solve = "";
          this.name = "";
          this.specification = "";
          this.unit = "";
          this.quantity = "";
          this.serialNumber = "";
          // 清空上传的文件
          let inputDOM = this.$refs.inputer;
          inputDOM.value = "";
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("提交失败");
        });
    },
  },
};
</script>

<style scoped>
.formMessage {
  width: 100%;
}
h2 {
  padding-top: 25px;
}
.input-full-width {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
