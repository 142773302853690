import Vue from 'vue'
import VueRouter from 'vue-router'

// 渲染的组件
import feedback_measurement from '@/views/Feedback_measurement/feedback_measure.vue' // 反馈测量页面
import Company_policies from '@/views/Company_policies/company_policies.vue'
import Control_system from '@/views/Control_system/control.vue'
import wisdom_camp from '@/views/wisdom_camp/wisdom_camp.vue'
import motor_drive from '@/views/Motor_drive/motor_drive.vue'
import system_integration from '@/views/system_integration/system_integration.vue'
import mechanical_transmission from '@/views/Mechanical_transmission/mechanical_transmission.vue'
// 用户及登录界面
import Center from '@/views/Center/Centers.vue'
import Msg from '@/views/msg/msg.vue'                 //投诉页面
import Register from '@/views/Register/register.vue'  //注册页面
import Cmd from '@/views/cmd/cmd.vue'
import Rmd from '@/views/rmd/rmd.vue'
import Information from '@/views/Information/information.vue' // 完善信息页面
// import Lottery from '@/views/Lottery/lottery.vue'
// import Cj from '@/components/cj.vue'
import authorize from '@/views/authorize/authorize.vue'
import Captcha from '@/views/captcha/captcha.vue'
import Frontend from '@/views/Frontend/Frontend.vue'
import Approval from '@/views/Approval/approval.vue'
Vue.use(VueRouter)

const routes = [
  // 主页
  {
    path: '/',
    redirect: '/center',
  },
  //抽奖页面  
  // {
  //   path: '/lottery',
  //   component: Lottery,
  //   name: 'lottery',
  //   meta: {
  //     requiresAuth: true,
  //     requiresSurvey: true,// 添加 requiresSurvey 到 meta 信息中
  //     requiresRegister: true
  //   }
  // },
  // {
  //   path: '/cj',
  //   component: Cj
  // },

  //授权页面
  {
    path: '/authorize',
    component: authorize,
  },
  // 审批页面
  {
    path: '/approval',
    component: Approval,
  },
  // 用户页
  {
    path: '/center',
    name: 'center',
    component: Center,
  },
  {
    path: '/captcha',
    name: 'captcha',
    component: Captcha,
  },
  {
    path: '/frontend',
    name: 'frontend',
    component: Frontend,
  },
  // 投诉页
  {
    path: '/msg',
    name: 'msg',
    component: Msg,
    meta: {
      requiresAuth: true,
      requiresRegister: true
    }
  },
  // 智汇营
  {
    path: '/wisdom_camp',
    name: 'wisdom_camp',
    component: wisdom_camp,
    meta: {
      requiresAuth: true,
      requiresRegister: true
    }
  },
  // 电机驱动
  {
    path: '/motor_drive',
    name: 'motor_drive',
    component: motor_drive,
  },
  // 反馈测量
  {
    path: '/feedback_measurement',
    name: 'feedback_measurement',
    component: feedback_measurement,
  },
  // 公司制度
  {
    path: '/Company_policies',
    name: 'Company_policies',
    component: Company_policies,
  },
  // 控制系统
  {
    path: '/Control_system',
    name: 'control_system',
    component: Control_system,
  },
  // 系统集成
  {
    path: '/system_integration',
    name: 'system_integration',
    component: system_integration,
  },
  // 机械传动
  {
    path: '/mechanical_transmission',
    name: 'mechanical_transmission',
    component: mechanical_transmission,
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/cmd',
    component: Cmd
  },
  {
    path: '/rmd',
    component: Rmd
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    meta: {
      requiresAuth: true,
      requiresRegister: true
    }
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 检查授权
    const hasAuthorized = localStorage.getItem('hasAuthorized');
    if (!hasAuthorized) {
      // 用户没有授权，跳转到微信授权页面
      window.location.href = `http://wx.servodynamics.com.cn/api/wechat`;
    } else {
      // 用户已经授权，检查是否已经注册
      const isRegistered = localStorage.getItem("isRegistered");
      if (to.matched.some(record => record.meta.requiresRegister) && !isRegistered) {
        next("/register");
        } else {
          next();
      }
    }
  } else {
    next();
  }
});
export default router