// 引入vue
import Vue from 'vue'

// 引入vuex
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        info: {},
        // isRegistered: false,
      },
    mutations: {
        saveInfo(state, payload){
            state.info = payload
        },
        updateInfo(state, payload) {
          state.info =  { ...state.info, ...payload };
        }
      }
})
