<template>
  <div>
    <div class="login">
      <img src="@/assets/loginserve.png" alt="" />
    </div>
    <van-form ref="modifyForm" validate-first>
      <van-field
        v-model.trim="name"
        label="公司名称"
        required
        type="name"
        placeholder=""
        :rules="[
          {
            required: true,
            message: '请输入正确的公司名称',
            trigger: 'blur',
          },
          {
            min: 4,
            message: '公司名称最少不低于4个字符',
            trigger: 'blur',
          },
          {
            max: 25,
            message: '公司名称不超过20个字符',
            trigger: 'blur',
          },
        ]"
        @input="handleSearch"
      />
      <el-dropdown v-if="showDropdown" @command="handleSelect">
        <el-dropdown-item
          v-for="option in filteredCompanyOptions"
          :key="option"
          :command="option"
        >
          {{ option }}
        </el-dropdown-item>
        <el-dropdown-menu> </el-dropdown-menu>
      </el-dropdown>

      <van-field
        v-model="phone"
        type="phone"
        label="手机号"
        :rules="[
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
          },
        ]"
      />
      <van-field
        v-model="email"
        type="email"
        label="邮箱"
        :rules="[
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: '请输入正确的邮箱',
          },
        ]"
      />
      <van-field v-model="department" label="部门"  />
      <van-field v-model="position" label="姓名"  />
      <div style="margin: 6px">
        <van-button
          round
          block
          type="info"
          native-type="modify"
          @click="updateUserInfo"
          >完善信息</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>

import axios from "axios";
import { Toast } from "vant";
import { mapState } from "vuex";
function filterSpecialCharacters(str) {
  // 这里可以根据实际需求编写过滤规则
  return str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "");
}
export default {
  name: "information",
    data() {
    return {
      name: "",
      phone: "",
      email: "",
      position: "",
      department: "",
      user: {},
      companyOptions: [], // 公司全称列表
      selectedCompany: "", // 用户选择的公司全称
      showDropdown: false, // 是否显示下拉框
    };
  },
  created() {
    this.Init();
    this.fetchUserInfo();
  },
  computed: {
    ...mapState(["info"]), // 使用mapState将store中的info映射到组件的computed属性中
    filteredCompanyOptions() {
      // 根据用户输入的公司名称过滤选项
      return this.companyOptions.filter((option) =>
        option.includes(filterSpecialCharacters(this.name))
      );
    },
  },
  methods: {
    // 验证公司名称的函数
    validateName(rule, value, callback) {
      const filteredValue = filterSpecialCharacters(value);
      if (filteredValue !== value) {
        callback(new Error("公司名称只能包含中文、英文字母和数字"));
      } else {
        callback(); // 验证通过
      }
    },
    async handleSearch() {
      try {
        const response = await axios.get(
          "http://wx.servodynamics.com.cn:9527/api/search",
          {
            params: {
              keyword: filterSpecialCharacters(this.name),
            },
          }
        );
        this.companyOptions = response.data.result.items.map(
          (item) => item.name
        ); // 将搜索结果存储到 searchResults 数组中
        this.showDropdown = true; // 显示下拉框

        // 处理返回的搜索结果
      } catch (error) {
        // console.error("Error searching:", error);
      }
    },
    handleSelect(command) {
      this.name = command;
      this.selectedCompany = command;
      this.showDropdown = false;
    },
    Init() {
      let str = localStorage.getItem("id");
    },
    async fetchUserInfo() {
      try {
        const userId = localStorage.getItem("id");
        if (userId != undefined) {
          const response = await axios.get(
            `http://wx.servodynamics.com.cn:9527/api/user/${userId}`
          );
          this.user = response.data.data[0];
          this.name = this.user.name; // 将获取到的数据赋值给相应的变量
          this.phone = this.user.phone;
          this.email = this.user.email;
          this.position = this.user.position; // 将获取到的数据赋值给相应的变量
          this.department = this.user.department;
          this.isRegistered = true;
        }
      } catch (error) {
        console.error("Error during fetchUserInfo: ", error);
      }
    },

    // 获取修改后的消息
    async updateUserInfo() {
      try {
        const userId = localStorage.getItem("id");
        const updatedInfo = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          department: this.department,
          position: this.position,
        };
        const response = await axios.put(
          `http://wx.servodynamics.com.cn:9527/api/userInfo/${userId}`,
          updatedInfo
        );
        if (response.data.message === "User info updated successfully!") {
          this.fetchUserInfo();
        }
      } catch (error) {
        console.error("Error during updateUserInfo: ", error);
      }

      // 校验手机号是否为空
      if (!this.phone) {
        Toast.fail("请输入手机号");
        return;
      }
      // 校验手机号格式
      const phoneRegex =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!phoneRegex.test(this.phone)) {
        Toast.fail("手机号格式不正确");
        return;
      }
      try {
        // 修改成功后的逻辑
        Toast.success("修改成功！");
        this.$router.push("/center");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
    <style lang="less" scoped>
.login {
  margin: 79px auto 40px;
  text-align: center;
  height: 100px;
  line-height: 60px;
  img {
    height: 100px;
  }
}
.login-info {
  .from-group {
    line-height: 55px;
    margin: 0 25px;
    position: relative;
    input {
      height: 15px;
      line-height: 15px;
      padding: 20px 0;
      width: 100%;
      font-size: 15px;
      color: #191a1b;
      border: 0;
      outline-width: 0;
    }
    .disable {
      position: absolute;
      right: 0px;
      top: 13px;
      color: #bdc0c5 !important;
      line-height: 30px;
      width: 90px;
      text-align: right;
      float: right;
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
    }
    .clear-btn {
      position: absolute;
      top: 20px;
      right: 90px;
      width: 18px;
      height: 18px;
    }
  }
  .from-group:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ededed;
    transform-origin: 0 0;
    border-bottom: 1px solid #ededed;
    transform: scaleY(0.5);
  }
  .submit {
    margin-top: 70px;
    line-height: 45px;
    font-size: 16px;
    margin: 70px 25px 0;
    border-radius: 3px;
    text-align: center;
    background-color: #ff5f16;
    height: 44px;
    color: #fff;
    border: none;
    span {
      line-height: 45px;
      font-size: 17px;
      margin: 70px 25px 0;
      border-radius: 3px;
      text-align: center;
      height: 44px;
      color: #ff8c58;
      border: none;
    }
  }
  .can-not-get {
    text-align: right;
    margin: 15px 25px 0;
    color: #797d82;
    font-size: 13px;
    span {
      text-align: right;
      color: #797d82;
      font-size: 13px;
    }
  }
  .small-checkbox {
    transform: scale(0.9);
  }
}
</style>