<template>
  <div>
    <div class="wisdom-camp-container">
      <div class="image-container">
        <img
          :src="imageSrc"
          alt="智汇营图片"
          class="responsive-image"
          ref="image"
        />
        <div class="carousel-overlay">
          <van-swipe type="mask" :show-indicators="false" >
            <van-swipe-item v-for="(item, id) in items" :key="id">
              <router-link :to="item.path">
                <h3 class="carousel-text">{{ item.title }}</h3>
              </router-link>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const titleToPathMap = {
  公司制度: "/Company_policies",
  电机驱动: "/motor_drive",
  机械传动: "/mechanical_transmission",
  系统集成: "/system_integration",
  反馈测量: "/feedback_measurement",
  控制系统: "/Control_system",
};
export default {
  data() {
    return {
      imageSrc:
        "http://wx.servodynamics.com.cn/source/static/wisdom_camp_image/row.gif",
      items: [],
    };
  },
  created() {
    this.mainData();
  },
  methods: {
    async mainData() {
      try {
        const res = await axios.get("http://wx.servodynamics.com.cn:9527/api/main_list_do");
        this.items = res.data.data.map((item) => ({
          title: item.title,
          path: titleToPathMap[item.title],
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wisdom-camp-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.carousel-overlay {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.el-carousel__item {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 隐藏指示器 */
.el-carousel__indicators {
  display: none !important;
}

.carousel-text {
  color: #fff;
  font-size: 38px;
  opacity: 0.9;
  text-align: center;
  margin: 0;
  font-family: "BoldFont", sans-serif;
  filter: brightness(1.2);
  -webkit-text-size-adjust: none;
}
// 媒体查询
@media (min-width: 1025px) {
  // PC
  .carousel-overlay {
    top: 50%; /* 调整到图片中间 */
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 600px;
  }
}

@media (max-width: 1024px) {
  // 平板和较大的手机
  .carousel-overlay {
    top: 50%; /* 调整到图片中间 */
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  // 小型平板和较大的手机
  .carousel-overlay {
    top: 50%; /* 调整到图片中间 */
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  // 手机
  .carousel-overlay {
    top: 55%; /* 调整到图片中间 */
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 200px;
  }
}
</style>