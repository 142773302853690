<template>
    <div>
        <h3 style="text-align: center">《⽤户使⽤协议》</h3>
      <h4>一、特别提⽰</h4>
      <p>1.1、为了更好地为您提供服务，请您仔细阅读这份协议。本协议是您与本应⽤就您登录本应⽤平台进⾏注册及使⽤等所涉及的全部⾏为所订⽴的权利义务规范。
      您在注册过程中点击“注册”等按钮、及注册后登录和使⽤时，均表明您已完全同意并接受本协议，愿意遵守本协议的各项规则、规范的全部内容，若不同意则
      可停⽌注册或使⽤本应⽤平台。如您是未成年⼈，您还应要求您的监护⼈仔细阅读本协议，并取得他/他们的同意。</p>
      <p>1.2、为提⾼⽤户的使⽤感受和满意度，⽤户同意本应⽤将基于⽤户的操作⾏为对⽤户数据进⾏调查研究和分析，从⽽进⼀步优化服务。</p>
      <h4>二、服务内容</h4>
      <p>2.1、平台内严禁⼀切⾮法、涉黄信息，违反社区运营规范者，⼀律封号处理。</p>
      <p>2.2、本应⽤服务的具体内容由本应⽤制作者根据实际情况提供。 </p>
      <p>2.3、除⾮本注册及服务协议另有其它明⽰规定，本应⽤所推出的新产品、新功能、新服务，均受到本注册及注册协议规范。</p>
      <p>2.4、本应⽤仅提供相关的⽹络服
      务，除此之外与相关⽹络服务有关的设备(如个⼈电脑、⼿机、及其他与接⼊互联⽹或移动⽹有关的装置)及所需的费⽤(如为接⼊互联⽹⽽⽀付的电话费及上
      ⽹费、为使⽤移动⽹⽽⽀付的⼿机费)均应由⽤户⾃⾏负担。</p>
      <h4>三、使⽤规则</h4>
      <p>3.1、⽤户帐号注册</p>
      <p>3.1.1、使⽤本应⽤系统注册的⽤户，只能使⽤汉字、英⽂字母、数字、下划线及它们的组合，禁⽌使⽤空格、各种符号和特殊字符，且最多不超过16个字符(8
      个汉字)注册，否则将不予注册。</p> 
      <p>3.1.2、使⽤第三⽅合作⽹站登录的⽤户，只能使⽤汉字、英⽂字母、数字、下划线及它们的组合，禁⽌使⽤空格、各种符号和特殊字符，且最多不超过14个字
      符(7个汉字)注册，否则本社区有权只截取前14个字符（7个汉字）予以显⽰⽤户帐号（若该⽤户帐号与应⽤现有⽤户帐号重名，系统将随机添加⼀个字符以
      ⽰区别），否则将不予注册。</p>
      <p>3.2、如发现⽤户帐号中含有不雅⽂字或不恰当名称的，全⾃动看⼴告保留取消其⽤户资格的权利。</p>
      <p>3.2.1、请勿以党和国家领导⼈或其他社会名⼈的真实姓名、字号、艺名、笔名注册；</p>
      <p>3.2.2、请勿以国家机构或其他机构的名称注册；</p> 
      <p>3.2.3、请勿注册不⽂明、不健康名字，或包含歧视、侮辱、猥亵类词语的帐号；</p>
      <p>3.2.4、请勿注册易产⽣歧义、引起他⼈误解或其它不符合法律规定的帐号。</p>
      <p>3.3、⽤户帐号的所有权归本应⽤，⽤户仅享有使⽤权。</p>
      <p>3.4、⽤户有义务保证密码和帐号的安全，⽤户利⽤该密码和帐号所进⾏的⼀切活动引起的任何损失或损害，由⽤户⾃⾏承担全部责任，本应⽤不承担任何责
      任。如⽤户发现帐号遭到未授权的使⽤或发⽣其他任何安全问题，应⽴即修改帐号密码并妥善保管，如有必要，请反馈通知本应⽤管理⼈员。因⿊客⾏为或
      ⽤户的保管疏忽导致帐号⾮法使⽤，本应⽤不承担任何责任。 </p>
      <p>3.5、⽤户承诺对其发表或者上传于本应⽤的所有信息(即属于《中华⼈民共和国著作权法》规定的作品，包括但不限于⽂字、图⽚、⾳乐、电影、表演和录⾳
      录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利⼈的合法授权；如⽤户违反本条规定造成本应⽤被第三⼈索赔的，⽤户应全额补偿本
      应⽤的⼀切费⽤(包括但不限于各种赔偿费、诉讼代理费及为此⽀出的其它合理费⽤)；</p>
      <p>3.6、当第三⽅认为⽤户发表或者上传于本应⽤的信息侵犯其权利，并根据《信息⽹络传播权保护条例》或者相关法律规定向本应⽤发送权利通知书时，⽤户同
      意本应⽤可以⾃⾏判断决定删除涉嫌侵权信息，除⾮⽤户提交书⾯证据材料排除侵权的可能性，本应⽤将不会⾃动恢复上述删除的信息；
      (1)不得为任何⾮法⽬的⽽使⽤⽹络服务系统；
      (2)遵守所有与⽹络服务有关的⽹络协议、规定和程序；
      (3)不得利⽤本应⽤的服务进⾏任何可能对互联⽹的正常运转造成不利影响的⾏为；
      (4)不得利⽤本应⽤服务进⾏任何不利于本应⽤的⾏为。</p>
      <p>3.7、如⽤户在使⽤⽹络服务时违反上述任何规定，本应⽤有权要求⽤户改正或直接采取⼀切必要的措施(包括但不限于删除⽤户上传的内容、暂停或终⽌⽤户
      使⽤⽹络服务的权利)以减轻⽤户不当⾏为⽽造成的影响。</p>
      <h4>四、责任声明</h4>
      <p>4.1、任何⽹站、单位或者个⼈如认为本应⽤或者本应⽤提供的相关内容涉嫌侵犯其合法权益，应及时向本应⽤提供书⾯权⼒通知，并提供⾝份证明、权属证
      明及详细侵权情况证明。本应⽤在收到上述法律⽂件后，将会尽快切断相关内容以保证相关⽹站、单位或者个⼈的合法权益得到保障。</p>
      <p>4.2、⽤户明确同意其使⽤本应⽤⽹络服务所存在的风险及⼀切后果将完全由⽤户本⼈承担，本应⽤对此不承担任何责任。</p>
      <p>4.3、本应⽤⽆法保证⽹络服务⼀定能满⾜⽤户的要求，也不保证⽹络服务的及时性、安全性、准确性。</p>
      <p>4.4、本应⽤不保证为⽅便⽤户⽽设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本应⽤实际控制的任何⽹页上的内容，本应⽤不承担
      任何责任。</p>
      <h4>五、知识产权 </h4>
      <p>5.1、本应⽤特有的标识、版⾯设计、编排⽅式等版权均属本应⽤享有，未经本应⽤许可授权，不得任意复制或转载。</p>
      <p>5.2、⽤户从本应⽤的服务中获得的信息，未经本应⽤的许可，不得任意复制或转载。</p> 
      <p>5.3、本应⽤的所有内容，包括商品描述、图⽚等内容所有权归属于全⾃动看⼴告APP的⽤户，任何⼈不得转载。</p> 
      <p>5.4、本应⽤所有⽤户上传内容仅代表⽤户⾃⼰的⽴场和观点，与本应⽤⽆关，由作者本⼈承担⼀切法律责任。</p>
      <p>5.5、上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经本应⽤、⽤户或相关权利⼈书⾯许可，任何⼈不得以任何形式进⾏使⽤或创造相关衍
      ⽣作品。</p>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  </style>